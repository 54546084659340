
import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/pages/LoginView.vue";
import EvaLayout from "../layouts/EvaLayout.vue";
import EvaWrapper from '../layouts/EvaWrapper.vue'
import store from "../store/index";

Vue.use(VueRouter);

const redirectToHomeOnLoggedIn = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next();
    // console.log(store.getters,'getters');
  } else{
    // console.log('dashboard')
    next(`/${store.getters.getUserRoleName}/dashboard`);
  }
};

const redirectToLoginOnLoggedOut = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    // console.log('next')
    next();
  } else {
    // console.log('next-/')
    next("/");
  }
};

const routes = [
  {
    path: "/",
    name: "LoginView",
    component: LoginView,
    meta: {
      authorize: [],
    },
    beforeEnter: redirectToHomeOnLoggedIn,
    // beforeRouteUpdate: redirectToHomeOnLoggedIn,
  },
  {
    path: `/:role`,
    name: "admin",
    redirect: "/dashboard",
    component: EvaWrapper,
    children: [
      {
        path: '/',
        name: 'profile-main',
        component: EvaLayout,
        children:[
          {
            path: "profile",
            name: "profile",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/ProfileView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
            beforeRouteUpdate: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'dashboard-main',
        component: EvaLayout,
        children:[
          {
            path: "dashboard",
            name: "dashboard",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/DashBoard.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
            beforeRouteUpdate: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'blog-main',
        component: EvaLayout,
        children:[
          {
            path: 'blog',
            name: "blog",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/BlogView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'case-study-main',
        component: EvaLayout,
        children:[
          {
            path: 'case-study',
            name: "case-study",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/CaseStudyView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'asset-management',
        component: EvaLayout,
        children:[
          {
            path: "assets",
            name: "assets",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/AssetsView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'teams',
        component: EvaLayout,
        children:[
          {
            path: "users",
            name: "users",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/UsersView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "user-roles",
            name: "user-roles",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/UserRoleView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "designations",
            name: "designations",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/DesignationView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'leave',
        component: EvaLayout,
        children:[
          {
            path: "holidays",
            name: "holidays",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/HolidayView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "leave-types",
            name: "leave-types",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/LeaveTypeView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "calendar",
            name: "calendar",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/CalendarView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "leave-request",
            name: "leave-request",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/LeaveRequestView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "leave-allocation",
            name: "leave-allocation",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/LeaveAllocationView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'leads',
        component: EvaLayout,
        children:[
          {
            path: "contactus-list",
            name: "contactus-list",
            meta: {
              leads: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/ContactListView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "estimate-list",
            name: "estimate-list",
            meta: {
              leads: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/EstimateListView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      }, 
      {
        path: '/',
        name: 'cms',
        component: EvaLayout,
        children:[
          {
            path: "services",
            name: "services",
            meta: {
              cms: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/CMS/servicesListView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "testimonials",
            name: "testimonials",
            meta: {
              cms: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/CMS/TestimonialView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "portfolios",
            name: "portfolios",
            meta: {
              cms: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/CMS/PortfolioView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "portfolio-category",
            name: "portfolio-category",
            meta: {
              cms: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/PortfolioCategories.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "clients",
            name: "clients",
            meta: {
              career: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/ClientsView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'careers',
        component: EvaLayout,
        children:[
          {
            path: "career-positions",
            name: "career-positions",
            meta: {
              career: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/CareerPositionView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "career-openings",
            name: "career-openings",
            meta: {
              career: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/CareerOpeningView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "career-applications",
            name: "career-applications",
            meta: {
              career: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/CareerApplicationsView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'error',
        component: EvaLayout,
        children:[
          {
            path: "404Error",
            name: "404Error",
            meta: {
              career: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/404View.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
    ],
  },
  // {
  //   path: "*",
  //   component: EvaLayout,
  //   redirect:`/${store.getters.getUserRoleName}/404Error`
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to, from,'to from');
  let menu = store.getters.getPermissions.length ? store.getters.getPermissions?.filter(item => (item.menu == to.name)) : []
    if(menu.length || (to.name == '404Error') || !store.getters.isLoggedIn  || (to.path == '/')){
      next()
    }else{
      next(`/${store.getters.getUserRoleName}/404Error`);
    }

})

export default router;

import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import users from "./modules/users";
import blog from "./modules/blog";
import common from "./modules/common";
import cms from "./modules/cms";
import careers from "./modules/careers";
import holidays from './modules/holidays';
import leaveTypes from "./modules/leaveTypes";
import leave from './modules/leave';
import caseStudy from "./modules/caseStudy";
import asset from "./modules/asset";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    users,
    blog,
    common,
    cms,
    careers,
    holidays,
    leaveTypes,
    leave,
    caseStudy,
    asset
  },
});

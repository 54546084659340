<template>
  <div class="form-floating mb-3">
    <input
      type="password"
      class="form-control admin_input"
      :class="v.$error ? 'is-invalid' : ''"
      required
      v-model="password"
      :placeholder="placeholder"
      id="floatingInputpass"
    />
    <label for="floatingInputpass">{{ label }}</label>

    <div class="invalid-feedback fw-bolder" v-if="v.$error">
      <span v-if="!v.required"> Password is required..! </span>
      <span v-if="v.required && !v.minlength">
        Password must be atleast 8 characters long.</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classProps: {
      type: String,
      required: false,
      default: "",
    },
    v: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.v.$touch();
        this.$emit("input", value);
      },
    },
  },
};
</script>

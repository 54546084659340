
// Auth module
import router from "@/router";
import axios from "axios";

const state = {
    loggedIn:false,
    user: {},
    user_id:null,
    permissions: {},
    currentUserRole: null,
    userRoleName:null,
    isAdmin:null,
    isModerator:null,
    hasAccess: null,
    sidebarState: false,
    editPermissions: {},
    allRoutes:[],
};

const getters = {
    // loginDt: (state) => state.user,
    isLoggedIn: state => {
        return state.loggedIn;
    },
    isAdmin: state => {
        return state.isAdmin;
    },
    isModerator: state => {
        // console.log(state.isModerator,'isModerator')
        return state.isModerator;
    },
    hasAccess: state => {
        return (state.isAdmin || state.isModerator) ? true : false;
    },
    getUser: state => {
        return state.user;
    },
    getUserId: state => {
        return state.user[0].id;
    },
    getPermissions: state => {
        return state.permissions;
    },
    getEditPermissions: state => {
        return state.editPermissions;
    },
    currentUserRole: state => {
        return state.currentUserRole;
    },
    getUserRoleName: state => {
        return state.userRoleName;
    },
    isSidebarState: state => {
        return state.sidebarState;
    }
};

const actions = {
    login: ({
        commit
    }, {
        token,
        user,
        user_role_name
    }) => {
        const accesstoken = `Bearer ${accesstoken}`;
        const user_role = user[0].user_role;

        localStorage.setItem("token", token);
        localStorage.setItem('user_role', user_role);
        
        commit("SetUser", user);
        commit("SetCurrentUser", user_role);
        commit("SetUserRoleName",user_role_name[0].name);

        // set auth header
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },

    permissions:({commit},{AllPermissions}) => {
        commit("setPermissions", AllPermissions)
    },

    editPermissions:({commit},{userPermissions}) => {
        commit('setEditPermissions', userPermissions)
    },

    logOut: ({commit}) => {
        localStorage.removeItem("token");
        localStorage.removeItem("user_role");

        commit("logOutUser");
    },

    sidebarState: ({commit}, isOpen) => {
        // console.log(isOpen, "commit")
        commit("SetsidebarState", isOpen)
    }
};

const mutations = {
    // login: (state, payload) => (state.user = payload.data),
    SetUser: (state, payload) => {
        // console.log(payload, "payload");
        state.user = payload;
        
    },
    SetCurrentUser: (state, payload) => {
        state.currentUserRole = payload;
        // console.log(state.user,'payload afads')
        state.isAdmin = (payload == 1) ? true : false;
    },
    SetUserRoleName: (state, payload) => {
        state.userRoleName = payload;
        // console.log(payload,'userrolename')
        state.isModerator = (payload == 'moderator') ? true : false;
    },
    setPermissions: (state, payload) => {
        state.permissions = payload;
        state.loggedIn = localStorage.getItem("token") ? true : false;
    },
    setEditPermissions: (state, payload) => {
        state.editPermissions = payload;
    },
    logOutUser: (state) => {
        state.user = {};
        state.loggedIn = localStorage.getItem("token") ? true : false;
        state.userRoleName = null;
    },
    SetsidebarState: (state, payload) => {
        state.sidebarState = payload;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
  </div>
</template>

<style lang="scss">
// Import Main styles for this application
// @import "styles/admin";
@import "assets/scss/admin";
</style>

<script>
// import axios from "axios";
// import store from "./store";
export default {
  // created: function () {
  //   axios.interceptors.response.use(undefined, function (err) {
  //     // eslint-disable-next-line no-unused-vars
  //     return new Promise(function (resolve, reject) {
  //       if (err.config) {
  //         console.log(err);
  //         store.dispatch("logOut");
  //         this.$router.push("/");
  //       }
  //       // if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
  //       //   // if you ever get an unauthorized, logout the user
  //       //   this.$store.dispatch(AUTH_LOGOUT);
  //       //   // you can also redirect to /login if needed !
  //       // }
  //       throw err;
  //     });
  //   });
  // },
};
</script>

const state = {
    allHolidays: [],
    holidayById: {},
    allHolidayEvents:[],
    optionalHolidayStrArr:[],
    publicHolidayStrArr:[]
}

const getters = {
    getAllHolidays: (state) => {
        return state.allHolidays;
    },
    getHolidayById: (state) => {
        return state.holidayById;
    },
    getHolidayEvents: (state) => {
        return state.allHolidayEvents;
    },
    getOptionalHolidayStrArr: (state) => {
        console.log(state.optionalHolidayStrArr)
        return state.optionalHolidayStrArr
    },
    getPublicHolidayStrArr: (state) => {
        return state.publicHolidayStrArr
    }
}

const actions = {
    getHolidays: ({ commit }, data) => {
        commit("SetAllHolidays", data);
    },

    getHolidayById: ({ commit }, data) => {
        commit("SetHolidayById", data);
    },

    createHolidays: ({ commit }, data) => {
        commit("SetAllHolidays", data);
    },
}

const mutations = {

    SetAllHolidays: (state, payload) => {
        // console.log(payload, "holidays");
        state.allHolidays = payload;
        state.allHolidayEvents = payload?.map(holiday => {
            return {
                title:holiday.name,
                start:holiday.date,
                textColor:(holiday.type == 'Public')?'red':'#ff7572',
                display:'background',
                backgroundColor:(holiday.type == 'Public')?'#ff7572':'',
            }})
        state.optionalHolidayStrArr = payload.filter(holiday => (holiday.type == "Optional")).map(holiday => holiday.date)
        state.publicHolidayStrArr = payload.filter(holiday => (holiday.type == "Public")).map(holiday => holiday.date)
    },

    SetHolidayById: (state, payload) => {
        // console.log(payload, "payload");
        state.holidayById = payload;
    }

}

export default {
    state,
    getters,
    actions,
    mutations,
};

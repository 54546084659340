<template>
  <div class="layout" :class="!isSideBarOpen ? 'sidebar-icon-only' : ''">
    <!-- <NavBar @isSideBarOpen="updateSideBarOpen" /> -->

    <div class="evalayout_page_wrpr">
      <SideBar :isOpen="isSideBarOpen" />

      <div class="main_panel">
        <NavBar @isSideBarOpen="updateSideBarOpen" />
        <div class="content-wrapper">
          <router-view />
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import SideBar from "../components/SideBar.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "dashoardView",
  components: {
    NavBar,
    SideBar,
    Footer,
  },

  data() {
    return {
      // isSideBarOpen: true,
    };
  },

  computed: {
    isSideBarOpen() {
      return this.$store.getters.isSidebarState;
    },
  },

  methods: {
    updateSideBarOpen(isOpen) {
      // console.log(this.$store.getters.isSidebarState);
      // this.isSideBarOpen = isOpen;
      this.$store.dispatch("sidebarState", isOpen);

      // this.isSideBarOpen = this.$store.getters.isSidebarState;
    },
  },
};
</script>

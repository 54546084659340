<template>
  <div class="showbox_btn_loader">
    <div class="loader">
      <svg class="circular" viewBox="25 25 50 50">
        <circle
          class="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="4"
          stroke-miterLimit="10"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderView",
};
</script>
<style lang="scss">
.showbox_btn_loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  //   background-color: #fff;
  opacity: 0.8;
  padding: 1rem;
  z-index: 99;

  .loader {
    position: relative;
    margin: 0 auto;
    width: 100px;
    height: 100%;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    .circular {
      animation: rotate 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite,
          color_btn 6s ease-in-out infinite;
        stroke-linecap: round;
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 899, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color_btn {
  100% {
  }

  0% {
    stroke: white;
  }

  40% {
    stroke: white;
  }

  66% {
    stroke: white;
  }

  80% {
  }

  90% {
    stroke: white;
  }
}
</style>

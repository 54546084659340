// Users module
// import axios from "axios";

const state = {
  allUsers: [],
  userbyId: {},

  reportingPersons:[],
  userTypeLevels:[],

  allUserRoles: [],
  userRoleById: {},

  allDesignations: [],
  designationById: {}
};

const getters = {
  getUsers: (state) => {
    return state.allUsers;
  },
  getUserbyId: (state) => {
    return state.userbyId;
  },
  getUserRoles: (state) => {
    return state.allUserRoles;
  },
  getReportingPersons: (state) => {
    return state.reportingPersons;
  },
  getUserTypeLevels: (state) => {
    return state.userTypeLevels;
  },
  getUserRoleById: (state) => {
    return state.userRoleById;
  },
  getDesignations: (state) => {
    return state.allDesignations;
  },
  getDesignationById: (state) => {
    return state.designationById;
  },
};

const actions = {
  getUsers: ({ commit }, users) => {
    commit("SetUsers", users);
  },
  createUser: ({ commit }, users) => {
    commit("SetUsers", users);
  },
  deleteUser: ({ commit }, users) => {
    commit("SetUsers", users);
  },
  getUserbyId: ({ commit }, user) => {
    commit("SetUserbyId", user);
  },
  editUser: ({ commit }, user) => {
    commit("SetUserbyId", user);
  },
  setUserTypeLevels:({ commit },data) => {
    commit("SET_USER_TYPE_LEVELS",data)
  },
  setReportingPersons: ({ commit },data) => {
    commit("SetReportingPersons",data)
  },
  getUserRoles: ({ commit }, data) => {
    commit("SetAllUserRoles", data);
  },
  getUserRoleById: ({ commit }, data) => {
    commit("SetUserRoleById", data);
  },
  createUserRoles: ({ commit }, data) => {
    commit("SetAllUserRoles", data);
  },
  deleteUserRole: ({ commit }, data) => {
    commit("SetAllUserRoles", data);
  },
  getDesignations: ({ commit }, data) => {
    commit("SetAllDesignations", data);
  },
  getDesignationById: ({ commit }, data) => {
    commit("SetDesignationById", data);
  },
  createDesignations: ({ commit }, data) => {
    commit("SetAllDesignations", data);
  },
  deleteDesignation: ({ commit }, data) => {
    commit("SetAllDesignations", data);
  },
};

const mutations = {
  // login: (state, payload) => (state.user = payload.data),
  SetUsers: (state, payload) => {
    // console.log(payload, "payload");
    state.allUsers = payload;
  },

  SetUserbyId: (state, payload) => {
    // console.log(payload, "payload");
    state.userbyId = payload;
  },

  SetReportingPersons: (state,payload) => {
    state.reportingPersons = payload;
  },

  SET_USER_TYPE_LEVELS: (state,payload) => {
    state.userTypeLevels = payload.userTypeLevels;
  },

  SetAllUserRoles: (state, payload) => {
    // console.log(payload, "payload");
    state.allUserRoles = payload;
  },

  SetUserRoleById: (state, payload) => {
    // console.log(payload, "payload");
    state.userRoleById = payload;
  },

  SetAllDesignations: (state, payload) => {
    // console.log(payload, "payload");
    state.allDesignations = payload;
  },

  SetDesignationById: (state, payload) => {
    // console.log(payload, "payload");
    state.designationById = payload;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};

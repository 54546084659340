// Users module
// import axios from "axios";

const state = {
    allCaseStudies: [],
    caseStudyById: {},
  };
  
  const getters = {
    getCaseStudies: (state) => {
      return state.allCaseStudies;
    },
    getCaseStudyById: (state) => {
      return state.caseStudyById;
    },
  };
  
  const actions = {
    setAllCaseStudies: ({ commit }, case_studies) => {
        // console.log(case_studies)
      commit("SetCaseStudies", case_studies);
    },
    createCaseStudy: ({ commit }, caseStudies) => {
      commit("SetCaseStudies", caseStudies);
    },
    getCaseStudyById: ({ commit }, case_study) => {
      commit("SetCaseStudyById", case_study);
    },
    deleteCaseStudy: ({ commit }, caseStudies) => {
      commit("SetCaseStudies", caseStudies);
    },
  };
  
  const mutations = {
    // login: (state, payload) => (state.user = payload.data),
    SetCaseStudies: (state, payload) => {
      // console.log(payload, "payload");
      state.allCaseStudies = payload;
    },
  
    SetCaseStudyById: (state, payload) => {
      // console.log(payload, "payload");
      state.caseStudyById = payload;
    },
  };
  
  export default {
    state,
    getters,
    actions,
    mutations,
  };
  
const state = {
    allAssets:[],
    assetById: {},
}

const getters = {
    getAllAssets: (state) => {
        return state.allAssets;
    },
    getAssetById: (state) => {
        return state.assetById;
    }
}

const actions = {

    setAssets: ({ commit }, data) => {
        commit("SET_ASSETS", data);
    },

    setAssetById: ({ commit }, data) => {
        commit("SET_ASSET_BY_ID", data);
    },
}

const mutations = {

    SET_ASSETS: (state,payload) => {
        state.allAssets = payload;
    },

    SET_ASSET_BY_ID: (state, payload) => {
        state.assetById = payload;
    }

}

export default {
    state,
    getters,
    actions,
    mutations,
};

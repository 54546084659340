<template>
  <!-- <div> -->
  <nav
    class="sidebar sidebar-offcanvas"
    :class="isOpen == true ? 'open' : ''"
    id="sidebar"
  >
    <div
      class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
    >
      <a class="navbar-brand brand-logo mr-5"  @click="routeTo('dashboard')">
        <img src="../assets/imgs/svg/evalogical.svg" alt="logo" class="mr-2" />
      </a>
      <!-- <a class="navbar-brand brand-logo-mini" href="#">
        <img src="../assets/imgs/svg/evalogical.svg" alt="logo" class="mr-2" />
      </a> -->
    </div>
    <ul class="nav">
      <li
        class="nav-item"
        :class="{
          active: isActive('dashboard' ),
          hideMenu: checkPermission('dashboard')
        }"
      >
        <a class="nav-link" @click="routeTo('dashboard')">
          <!-- <i class="icon-grid menu-icon"></i> -->
          <!-- <i class="bi-grid-fill menu-icon"></i> -->
          <span class="icon-StyleTwo-tone menu-icon">
            <span class="path1 activ_clr"></span>
            <span class="path2 activ_clr"></span>
            <span class="path3 activ_clr"></span>
          </span>
          <span class="menu-title">Dashboard</span>
        </a>
      </li>
      <li
        class="nav-item"
        :class="{ hideMenu: checkPermission('teams',1)}"
      >
      <!-- //@click="routeTo('users')" -->
        <a class="nav-link" v-b-toggle.collapse-1 >  
          <!-- <i class="bi bi-people-fill menu-icon"></i> -->
          <span class="icon-StyleTwo-tone-3 menu-icon">
            <span class="path1 activ_clr"></span>
            <span class="path2 activ_clr"></span>
            <span class="path3 activ_clr"></span>
            <span class="path4 activ_clr"></span>
          </span>
          <span class="menu-title">Teams</span>
          <i
            class="icon-arrow-right-2-svgrepo-com ms-auto"
            :class="{ open: isOpenTeams }"
          ></i>
        </a>
        <b-collapse
          id="collapse-1"
          v-model="isOpenTeams"
          accordion="sidebar_options"
        >
          <!-- <div> -->
          <ul class="nav flex-column sub-menu">
            <li
              class="nav-item"
              :class="{
                active: isActive('users' ),
                hideMenu: checkPermission('users')
              }"
            >
              <a class="nav-link" @click="routeTo('users')">
                <!-- <i class="icon-StyleTwo-tone-3 me-2"></i> -->
                <span class="icon-user-svgrepo-com1 menu-icon">
                  <span class="path1 activ_clr"></span>
                  <span class="path2 activ_clr"></span>
                  <span class="path3 activ_clr"></span>
                  <span class="path4 activ_clr"></span>
                </span>
                Users
              </a>
            </li>
            <li
              class="nav-item"
              :class="{
                active: isActive('user-roles' ),
                hideMenu: checkPermission('user-roles')
              }"
            >
              <a class="nav-link" @click="routeTo('user-roles')">
                <!-- <i class="icon-phone me-2"></i> -->
                <span class="icon-StyleTwo-tone-3 menu-icon">
                  <span class="path1 activ_clr"></span>
                  <span class="path2 activ_clr"></span>
                  <span class="path3 activ_clr"></span>
                  <span class="path4 activ_clr"></span>
                </span>
                User Roles
              </a>
            </li>
            <li
              class="nav-item"
              :class="{
                  active: isActive('designations' ),
                  hideMenu: checkPermission('designations')
              }"
            >
              <a class="nav-link" @click="routeTo('designations')">
                <!-- <i class="icon-phone me-2"></i> -->
                <span class="icon-briefcase menu-icon">
                  <span class="path1 activ_clr"></span>
                  <span class="path2 activ_clr"></span>
                  <span class="path3 activ_clr"></span>
                  <span class="path4 activ_clr"></span>
                </span>
                Designations
              </a>
            </li>
          </ul>
          <!-- </div> -->
        </b-collapse>
      </li>

      <!-- Leave Routes -->
      <li
        class="nav-item"
        :class="{hideMenu: checkPermission('leave',1)}"
      >
      <a class="nav-link" v-b-toggle.collapse-5 >  
          <!-- <i class="bi bi-people-fill menu-icon"></i> -->
          <span class="icon-StyleTwo-tone-5 menu-icon">
            <span class="path1 activ_clr"></span>
            <span class="path2 activ_clr"></span>
            <span class="path3 activ_clr"></span>
            <span class="path4 activ_clr"></span>
            <span class="path5 activ_clr"></span>
            <span class="path6 activ_clr"></span>
          </span>
          <span class="menu-title">Leave</span>
          <i
            class="icon-arrow-right-2-svgrepo-com ms-auto"
            :class="{ open: isOpenLeave }"
          ></i>
        </a>
        <b-collapse
          id="collapse-5"
          v-model="isOpenLeave"
          accordion="sidebar_options"
        >
          <!-- <div> -->
          <ul class="nav flex-column sub-menu">
            <li
              class="nav-item"
              :class="{
                active: isActive('holidays' ),
                hideMenu: checkPermission('holidays')
              }"
            >
              <a class="nav-link" @click="routeTo('holidays')">
                <!-- <i class="icon-phone me-2"></i> -->
                <span class="icon-sun menu-icon">
                  <span class="path1 activ_clr"></span>
                  <span class="path2 activ_clr"></span>
                  <span class="path3 activ_clr"></span>
                  <span class="path4 activ_clr"></span>
                </span>
                Holidays
              </a>
            </li>
            <li
              class="nav-item"
              :class="{
                active: isActive('leave-types' ),
                hideMenu: checkPermission('leave-types')
              }"
            >
              <a class="nav-link" @click="routeTo('leave-types')">
                <!-- <i class="icon-phone me-2"></i> -->
                <span class="icon-package menu-icon">
                  <span class="path1 activ_clr"></span>
                  <span class="path2 activ_clr"></span>
                  <span class="path3 activ_clr"></span>
                  <span class="path4 activ_clr"></span>
                </span>
                Leave Types
              </a>
            </li>
            <li
              class="nav-item"
              :class="{
                active: isActive('leave-allocation' ),
                hideMenu: checkPermission('leave-allocation')
              }"
            >
              <a class="nav-link" @click="routeTo('leave-allocation')">
                <!-- <i class="icon-phone me-2"></i> -->
                <span class="icon-git-merge menu-icon">
                  <span class="path1 activ_clr"></span>
                  <span class="path2 activ_clr"></span>
                  <span class="path3 activ_clr"></span>
                  <span class="path4 activ_clr"></span>
                </span>
                Leave Allocation
              </a>
            </li>
            <li
              class="nav-item"
              :class="{
                active: isActive('calendar' ),
                hideMenu: checkPermission('calendar')
              }"
            >
              <a class="nav-link" @click="routeTo('calendar')">
                <!-- <i class="icon-phone me-2"></i> -->
                <span class="icon-StyleTwo-tone-5 menu-icon">
                  <span class="path1 activ_clr"></span>
                  <span class="path2 activ_clr"></span>
                  <span class="path3 activ_clr"></span>
                  <span class="path4 activ_clr"></span>
                  <span class="path5 activ_clr"></span>
                  <span class="path6 activ_clr"></span>
                </span>
                Calendar
              </a>
            </li>
            <li
              class="nav-item"
              :class="{
                active: isActive('leave-request' ),
                hideMenu: checkPermission('leave-request')
              }"
            >
              <a class="nav-link" @click="routeTo('leave-request')">
                <!-- <i class="icon-phone me-2"></i> -->
                <span class="icon-layers menu-icon">
                  <span class="path1 activ_clr"></span>
                  <span class="path2 activ_clr"></span>
                  <span class="path3 activ_clr"></span>
                  <span class="path4 activ_clr"></span>
                  <span class="path5 activ_clr"></span>
                  <span class="path6 activ_clr"></span>
                </span>
                Leave Request
              </a>
            </li>
          </ul>
          <!-- </div> -->
        </b-collapse>
      </li>

      <!-- asset Management-->
      <li
        class="nav-item"
        :class="{
          active: isActive('assets' ),
          hideMenu: checkPermission('assets')
        }"
      >
        <a class="nav-link" @click="routeTo('assets')">
          <!-- <i class="bi bi-blockquote-left menu-icon"></i> -->
          <span class="icon-archive menu-icon">
            <span class="path1 activ_clr"></span>
            <span class="path2 activ_clr"></span>
            <span class="path3 activ_clr"></span>
            <span class="path4 activ_clr"></span>
            <span class="path5 activ_clr"></span>
          </span>
          <span class="menu-title">Assets</span>
        </a>
      </li>

      <li class="nav-item" :class="{hideMenu: checkPermission('leads',1)}">
        <a class="nav-link" v-b-toggle.collapse-4>
          <!-- <i class="bi bi-person-rolodex menu-icon"></i> -->
          <span class="icon-StyleTwo-tone-46 menu-icon">
            <span class="path1 activ_clr"></span>
            <span class="path2 activ_clr"></span>
            <span class="path3 activ_clr"></span>
            <span class="path4 activ_clr"></span>
          </span>
          <span class="menu-title">Leads</span>

          <i
            class="icon-arrow-right-2-svgrepo-com ms-auto"
            :class="{ open: isOpenLeads }"
          ></i>
        </a>
        <b-collapse
          id="collapse-4"
          v-model="isOpenLeads"
          accordion="sidebar_options"
        >
          <!-- <div> -->
          <ul class="nav flex-column sub-menu">
            <li
              class="nav-item"
              :class="{
                active: isActive('contactus-list' ),
                hideMenu: checkPermission('contactus-list')
              }"
            >
              <a class="nav-link" @click="routeTo('contactus-list')">
                <!-- <i class="icon-phone me-2"></i> -->
                <span class="icon-StyleTwo-tone-100 menu-icon">
                  <span class="path1 activ_clr"></span>
                  <span class="path2 activ_clr"></span>
                  <span class="path3 activ_clr"></span>
                  <span class="path4 activ_clr"></span>
                </span>
                Contact Us
              </a>
            </li>
            <li
              class="nav-item"
              :class="{
                active: isActive('estimate-list' ),
                hideMenu: checkPermission('estimate-list')
              }"
            >
              <a class="nav-link" @click="routeTo('estimate-list')">
                <!-- <i class="icon-file-text me-2"></i> -->
                <span class="icon-StyleTwo-tone-85 menu-icon">
                  <span class="path1 activ_clr"></span>
                  <span class="path2 activ_clr"></span>
                  <span class="path3 activ_clr"></span>
                  <span class="path4 activ_clr"></span>
                </span>
                Estimate
              </a>
            </li>
          </ul>
          <!-- </div> -->
        </b-collapse>
      </li>

      <li
        class="nav-item"
        :class="{
          active: isActive('blog' ),
          hideMenu: checkPermission('blog')
        }"
      >
        <a class="nav-link" @click="routeTo('blog')">
          <!-- <i class="bi bi-blockquote-left menu-icon"></i> -->
          <span class="icon-StyleTwo-tone-54 menu-icon">
            <span class="path1 activ_clr"></span>
            <span class="path2 activ_clr"></span>
            <span class="path3 activ_clr"></span>
            <span class="path4 activ_clr"></span>
            <span class="path5 activ_clr"></span>
          </span>
          <span class="menu-title">Blog</span>
        </a>
      </li>
      <li
        class="nav-item"
        :class="{
          active: isActive('case-study' ),
          hideMenu: checkPermission('case-study')
        }"
      >
        <a class="nav-link" @click="routeTo('case-study')">
          <!-- <i class="bi bi-blockquote-left menu-icon"></i> -->
          <span class="icon-StyleTwo-tone-54 menu-icon">
            <span class="path1 activ_clr"></span>
            <span class="path2 activ_clr"></span>
            <span class="path3 activ_clr"></span>
            <span class="path4 activ_clr"></span>
            <span class="path5 activ_clr"></span>
          </span>
          <span class="menu-title">Case Study</span>
        </a>
      </li>

      <li class="nav-item" :class="{hideMenu: checkPermission('cms',1)}">
        <a class="nav-link" v-b-toggle.collapse-2>
          <!-- <i class="bi bi-cloud-fill menu-icon"></i> -->
          <span class="icon-StyleTwo-tone-109 menu-icon">
            <span class="path1 activ_clr"></span>
            <span class="path2 activ_clr"></span>
            <span class="path3 activ_clr"></span>
            <span class="path4 activ_clr"></span>
          </span>
          <span class="menu-title">CMS</span>

          <i
            class="icon-arrow-right-2-svgrepo-com ms-auto"
            :class="{ open: isOpenCMS }"
          ></i>
        </a>
        <b-collapse
          id="collapse-2"
          v-model="isOpenCMS"
          accordion="sidebar_options"
        >
          <div>
            <ul class="nav flex-column sub-menu">
              <li
                class="nav-item"
                :class="{
                  active: isActive('services' ),
                  hideMenu: checkPermission('services')
                }"
              >
                <a class="nav-link" @click="routeTo('services')">
                  <!-- <i class="icon-settings me-2"></i> -->
                  <span class="icon-StyleTwo-tone-23 menu-icon">
                    <span class="path1 activ_clr"></span>
                    <span class="path2 activ_clr"></span>
                    <span class="path3 activ_clr"></span>
                    <span class="path4 activ_clr"></span>
                  </span>
                  Services
                </a>
              </li>

              <li
                class="nav-item"
                :class="{
                  active: isActive('testimonials' ),
                  hideMenu: checkPermission('testimonials')
                }"
              >
                <a class="nav-link" @click="routeTo('testimonials')">
                  <!-- <i class="icon-message-square me-2"></i> -->
                  <span class="icon-StyleTwo-tone-92 menu-icon">
                    <span class="path1 activ_clr"></span>
                    <span class="path2 activ_clr"></span>
                    <span class="path3 activ_clr"></span>
                    <span class="path4 activ_clr"></span>
                    <span class="path5 activ_clr"></span>
                  </span>
                  Testimonials
                </a>
              </li>

              <li
                class="nav-item"
                :class="{
                  active: isActive('portifolio-category' ),
                  hideMenu: checkPermission('portfolio-category')
                }"
              >
                <a class="nav-link" @click="routeTo('portfolio-category')">
                  <!-- <i class="icon-settings me-2"></i> -->
                  <span class="icon-category-svgrepo-com menu-icon">
                    <span class="path1 activ_clr"></span>
                    <span class="path2 activ_clr"></span>
                    <span class="path3 activ_clr"></span>
                    <span class="path4 activ_clr"></span>
                  </span>
                  Portfolio Categories
                </a>
              </li>

              <li
                class="nav-item"
                :class="{
                  active: isActive('portfolios' ),
                  hideMenu: checkPermission('portfolios')
                }"
              >
                <a class="nav-link" @click="routeTo('portfolios')">
                  <!-- <i class="icon-settings me-2"></i> -->
                  <span class="icon-StyleTwo-tone-44 menu-icon">
                    <span class="path1 activ_clr"></span>
                    <span class="path2 activ_clr"></span>
                    <span class="path3 activ_clr"></span>
                    <span class="path4 activ_clr"></span>
                  </span>
                  Portfolios
                </a>
              </li>

              <li
                class="nav-item"
                :class="{
                  active: isActive('clients' ),
                  hideMenu: checkPermission('clients')
                }"
              >
                <a class="nav-link" @click="routeTo('clients')">
                  <!-- <i class="icon-settings me-2"></i> -->
                  <span class="icon-StyleTwo-tone-3 menu-icon">
                    <span class="path1 activ_clr"></span>
                    <span class="path2 activ_clr"></span>
                    <span class="path3 activ_clr"></span>
                    <span class="path4 activ_clr"></span>
                    <span class="path5 activ_clr"></span>
                    <span class="path6 activ_clr"></span>
                    <span class="path7 activ_clr"></span>
                    <span class="path8 activ_clr"></span>
                  </span>
                  Clients
                </a>
              </li>
            </ul>
          </div>
        </b-collapse>
      </li>

      <li class="nav-item" :class="{hideMenu: checkPermission('careers',1)}">
        <a class="nav-link" v-b-toggle.collapse-3>
          <!-- <i class="bi bi-cloud-fill menu-icon"></i> -->
          <span class="icon-StyleTwo-tone-45 menu-icon">
            <span class="path1 activ_clr"></span>
            <span class="path2 activ_clr"></span>
            <span class="path3 activ_clr"></span>
            <span class="path4 activ_clr"></span>
          </span>
          <span class="menu-title">Careers</span>

          <i
            class="icon-arrow-right-2-svgrepo-com ms-auto"
            :class="{ open: isOpenCareer }"
          ></i>
        </a>
        <b-collapse
          id="collapse-3"
          v-model="isOpenCareer"
          accordion="sidebar_options"
        >
          <div>
            <ul class="nav flex-column sub-menu">
              <li
                class="nav-item"
                :class="{
                  active: isActive('career-positions' ),
                  hideMenu: checkPermission('career-positions')
                }"
              >
                <a class="nav-link" @click="routeTo('career-positions')">
                  <!-- <i class="icon-settings me-2"></i> -->
                  <span class="icon-user-svgrepo-com menu-icon">
                    <span class="path1 activ_clr"></span>
                    <span class="path2 activ_clr"></span>
                    <span class="path3 activ_clr"></span>
                    <span class="path4 activ_clr"></span>
                  </span>
                  Career Positions
                </a>
              </li>

              <li
                class="nav-item"
                :class="{
                  active: isActive('career-openings' ),
                  hideMenu: checkPermission('career-openings')
                }"
              >
                <a class="nav-link" @click="routeTo('career-openings')">
                  <!-- <i class="icon-settings me-2"></i> -->
                  <span class="icon-activity-svgrepo-com menu-icon">
                    <span class="path1 activ_clr"></span>
                    <span class="path2 activ_clr"></span>
                    <span class="path3 activ_clr"></span>
                    <span class="path4 activ_clr"></span>
                  </span>
                  Career Openings
                </a>
              </li>

              <li
                class="nav-item"
                :class="{
                  active: isActive('career-applications' ),
                  hideMenu: checkPermission('career-applications')
                }"
              >
                <a class="nav-link" @click="routeTo('career-applications')">
                  <!-- <i class="icon-settings me-2"></i> -->
                  <span class="icon-StyleTwo-tone-7 menu-icon">
                    <span class="path1 activ_clr"></span>
                    <span class="path2 activ_clr"></span>
                    <span class="path3 activ_clr"></span>
                    <span class="path4 activ_clr"></span>
                  </span>
                  Career Applications
                </a>
              </li>
            </ul>
          </div>
        </b-collapse>
      </li>
    </ul>
  </nav>
  <!-- </div> -->
</template>

<script>
import router from '@/router';
import store from '@/store';

export default {
  name: "SideBar",
  props: ["isOpen"],
  data() {
    return {
      activeItem: "dashboard",
      url_data: null,
      user_role: localStorage.getItem("user_role"),
      isOpenCMS: false,
      isOpenLeads: false,
      isOpenCareer: false,
      isOpenTeams:false,
      isOpenLeave:false,
      isOpenAssets:false
    };
  },
  mounted() {
    this.url_data = this.$route.name;

    this.activeItem = this.$route.name;

    // console.log(this.$route);
    // console.log(this.permissions,'dash_perm')
  },
  methods: {
    routeTo(route) {
        this.$router.push( route);
        this.activeItem = `${route}`;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },

    checkPermission(menu,main){
      if(main){
      //  let menus = store.getters.getAllRoutes
      let menus = router.options.routes[1].children
      //  console.log(menus)
       let mainMenu = menus.filter(el => (el.name == menu))
       let subMenus = mainMenu[0].children.map(el => el.name)
       let permissions = this.permissions.map(item => item.menu);
       return subMenus.some(item => permissions.includes(item)) ? 0 : 1
      }
      let menus = this.permissions.filter(item => (item.menu == menu));
      if(menus.length){
        return 0
      }
      return 1
    }
  },

  computed: {
    permissions(){
     return Array.from(this.$store.getters.getPermissions)
    },
    isOpens: function (el) {
      // console.log(el);
      const a = el;
      return a;
    },
  },
};
</script>

export default {
  apiGateWay: {
    // CLIENT_ID: 4,
    // CLIENT_SECRET: "7OFpJM2yHAIonTAslDK3QIXbhrxmWATqZSKGKykP",

    // // local
    // CLIENT_ID: 12,
    // CLIENT_SECRET: "71VJLIZis47Awb0zwx5zcslfpYOk8vF2F8TwYyBB",

    // URL: "http://localhost:8000/api/",
    // DOMAIN: 'http://localhost:8000',

    // // staging
    CLIENT_ID: 6,
    CLIENT_SECRET: "R00fmmW3BQUhJbCXSyv7U0drGXybc4z8kxFq4Lxp",

    URL: "https://eva22.evalogical.in/api/",
    DOMAIN: "https://eva22.evalogical.in",
  },
};
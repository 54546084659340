import moment from "moment";

const state = {
    allLeaveAllocations:[],
    leaveAllocationByUserId:{},
    leaveAllocationByUserIdRaw:{},
    leaveRequestByUserId: [],
    leaveRequestById:{},
    leaveRequestEvents:[]
}

const getters = {
    getLeaveAllocations : (state) => {
        return state.allLeaveAllocations;
    },
    getLeaveAllocationsByUserId : (state) => {
        return state.leaveAllocationByUserId
    },
    getLeaveAllocationsByUserIdRaw : (state) => {
        return state.leaveAllocationByUserIdRaw
    },
    getLeaveRequestByUserId : (state) => {
        return state.leaveRequestByUserId
    },
    getLeaveRequestById : (state) => {
        return state.leaveRequestById
    },
    getLeaveRequestEvents : (state) => {
        return state.leaveRequestEvents
    }
}

const actions = {
    setLeaveAllocations : ({commit}, data) => {
        commit('SET_LEAVE_ALLOCATIONS', data);
    },
    setLeaveAllocationByUserId : ({commit},data) => {
        commit('SET_LEAVE_ALLOCATIONS_BY_USER_ID',data)
    },
    setLeaveRequestByUserId : ({commit},data) => {
        commit("SET_LEAVE_REQUEST_BY_USER_ID",data)
    },
    setLeaveRequestById : ({commit},data) => {
        commit("SET_LEAVE_REQUEST_BY_ID",data)
    }
}

const mutations = {
    SET_LEAVE_ALLOCATIONS : (state,payload) => {
        let leave_allocations = payload.map(el => {
            return {...el,leave_types: JSON.parse(el.leave_types)}
        });

        let leave = leave_allocations.map(el => {
            let leave_types = el.leave_types
            let user_name = {userName:el.userName,id:el.user_id}
            let allocations = leave_types.map(type => {return {[`${type.leave_type}_allocation`]:type.allocation,
            [`${type.leave_type}_taken`]:type.leave_taken? type.leave_taken : 0}})
            return Object.assign({},user_name,...allocations)
        }).flat();
        
        state.allLeaveAllocations = leave
    },
    SET_LEAVE_ALLOCATIONS_BY_USER_ID : (state,payload) => {
        if(payload.length){
        let leave_allocations = payload.map(el => {
            return {...el,leave_types: JSON.parse(el.leave_types)}
        });
        // console.log(leave_allocations,'leave_allcoations')

        state.leaveAllocationByUserIdRaw = leave_allocations;

        let leave = leave_allocations.map(el => {
            let leave_types = el.leave_types
            let user_name = {userName:el.userName,id:el.user_id}
            let allocations = leave_types.map(type => {return {[`${type.leave_type}_allocation`]:type.allocation,
            [`${type.leave_type}_taken`]:type.leave_taken? type.leave_taken : 0}})
            return Object.assign({},user_name,...allocations)
        }).flat();

        state.leaveAllocationByUserId = leave;
    } else{
        state.leaveAllocationByUserIdRaw = payload;
        state.leaveAllocationByUserId = payload;
    }
    },

    SET_LEAVE_REQUEST_BY_USER_ID : (state,payload) => {
        // console.log(payload,'payload')
        state.leaveRequestByUserId = payload;
        state.leaveRequestEvents = payload.map(item => 
            {
                return {
                id:item.id,
                title:`${item.abbrv} - ${item.status}`,
                start:item.start_date,
                end:item.end_date ? moment(item.start_date, "YYYY-MM-DD").add(item.no_of_days, 'd').format('YYYY-MM-DD') : '',
                color:item.color,
                classNames:(item.status == 'pending') ? 'eventPending' : ''}
            });
    },
    SET_LEAVE_REQUEST_BY_ID : (state,payload) => {
        state.leaveRequestById = payload[0];
    }
}

export default{
    state,
    getters,
    actions,
    mutations
}
// Common module

const state = {
  allContacts: [],
  allEstimates: [],
  estimateById: {},
};

const getters = {
  getAllContacts: (state) => {
    return state.allContacts;
  },

  getAllEstimate: (state) => {
    return state.allEstimates;
  },

  getEstimateById: (state) => {
    return state.getEstimateById;
  },
};

const actions = {
  getAllContacts: ({ commit }, data) => {
    commit("SetAllContacts", data);
  },

  getAllEstimates: ({ commit }, data) => {
    commit("SetAllEstimates", data);
  },

  getEstimateById: ({ commit }, data) => {
    commit("SetEstimateById", data);
  },

  deleteEstimate: ({ commit }, data) => {
    commit("SetAllEstimates", data);
  },
};

const mutations = {
  SetAllContacts: (state, payload) => {
    // console.log(payload, "payload");
    state.allContacts = payload;
  },

  SetAllEstimates: (state, payload) => {
    // console.log(payload, "payload");
    state.allEstimates = payload;
  },

  SetEstimateById: (state, payload) => {
    // console.log(payload, "payload");
    state.getEstimateById = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <nav class="navbar p-0">
    <div
      class="navbar-menu-wrapper d-flex align-items-center justify-content-end"
    >
      <button
        class="navbar-toggler navbar-toggler align-self-center"
        :class="isSideBarOpen ? 'open' : 'close'"
        type="button"
        data-toggle="minimize"
        @click="openSideBar"
      >
        <i class="icon-menu"></i>
      </button>

      <!-- <ul class="navbar-nav mr-lg-2">
        <li class="nav-item nav-search d-none d-lg-block">
          <div class="search_wrpr">
            <input
              type="text"
              class="form-control navbar-search-input"
              id="navbar-search-input"
              placeholder="Search now"
              aria-label="search"
              aria-describedby="search"
            />

            <span class="input-group-text srch_btn" id="search">
              <i class="bi bi-search"></i>
            </span>
          </div>
        </li>
      </ul> -->
      <ul class="navbar-nav navbar-nav-right">
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link count-indicator dropdown-toggle"
            id="notificationDropdown"
            href="#"
            data-toggle="dropdown"
            @click="NotificationOpen"
          >
            <i class="bi bi-bell mx-0"></i>
            <span class="count"></span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list show"
            ref="NotificationOpenRef"
            v-if="isNotificationOpen"
            aria-labelledby="notificationDropdown"
          >
            <p class="mb-0 font-weight-normal float-left dropdown-header">
              Notifications
            </p>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-success">
                  <i class="ti-info-alt mx-0"></i>
                </div>
              </div>
              <div class="preview-item-content">
                <h6 class="preview-subject font-weight-normal">
                  Application Error
                </h6>
                <p class="font-weight-light small-text mb-0 text-muted">
                  Just now
                </p>
              </div>
            </a>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-warning">
                  <i class="ti-settings mx-0"></i>
                </div>
              </div>
              <div class="preview-item-content">
                <h6 class="preview-subject font-weight-normal">Settings</h6>
                <p class="font-weight-light small-text mb-0 text-muted">
                  Private message
                </p>
              </div>
            </a>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-info">
                  <i class="ti-user mx-0"></i>
                </div>
              </div>
              <div class="preview-item-content">
                <h6 class="preview-subject font-weight-normal">
                  New user registration
                </h6>
                <p class="font-weight-light small-text mb-0 text-muted">
                  2 days ago
                </p>
              </div>
            </a>
          </div>
        </li> -->
        <li class="nav-item nav-profile dropdown">
          <a
            ref="button"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            id="profileDropdown"
            @click="ProfileSettingsOpen"
          >
            <img src="../assets/imgs/img/prfl_plchlder.png" alt="profile" />
          </a>
          <div
            class="dropdown-menu dropdown-menu-right navbar-dropdown show"
            ref="ProfileSettingsOpenRef"
            aria-labelledby="profileDropdown"
            v-show="isProfileSettingsOpen"
            v-closable="{
              exclude: ['button'],
              handler: 'ProfileSettingsClose',
            }"
          >
            <ul class="p-0">
              <li @click="routeTo('profile')" class="dropdown-item">
                <i class="bi bi-person"></i>
                Profile
              </li>
              <li class="dropdown-item" @click="logOut">
                <i class="bi bi-power"></i>
                Logout
              </li>
            </ul>
          </div>
        </li>
        <!-- <li class="nav-item nav-settings d-none d-lg-flex">
                    <a class="nav-link" href="#">
                        <i class="icon-ellipsis"></i>
                    </a>
                </li> -->
      </ul>
      <!-- <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                    data-toggle="offcanvas">
                    <span class="icon-menu"></span>
                </button> -->
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavbarView",
  data() {
    return {
      isNotificationOpen: false,
      isProfileSettingsOpen: false,
      isSideBarOpen: false,
    };
  },
  methods: {
    routeTo(route) {
      this.isProfileSettingsOpen = false;
        this.$router.push( route);
    },
    NotificationOpen() {
      // this.isNotificationOpen = !this.isNotificationOpen;
    },
    ProfileSettingsOpen() {
      this.isProfileSettingsOpen = !this.isProfileSettingsOpen;
    },
    ProfileSettingsClose() {
      this.isProfileSettingsOpen = false;
    },
    openSideBar() {
      this.isSideBarOpen = !this.isSideBarOpen;

      this.emitToSideBar(this.isSideBarOpen);
    },
    emitToSideBar(isSideBarOpen) {
      // console.log(isSideBarOpen)
      this.$emit("isSideBarOpen", isSideBarOpen);
    },

    logOut() {
      // console.log("logout");
      this.$store.dispatch("logOut");
      this.$router.push("/");
    },
  },
};
</script>
